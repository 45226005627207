<script>
import { ArrowRightIcon } from "vue-feather-icons";

export default {
  components: { ArrowRightIcon },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- Features start -->
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-7 text-center">
            <h2 class="fw-bold">Our Products</h2>
            <p class="text-muted h5">
              SaasLayer is Serverless Identity Management, Analytics and Admin
              Builder that can easily incorporated into your product, so you can
              spend more time on your unique value proposition.
            </p>
          </div>
        </div>

        <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5">
            <h2 class="mb-4">Identity and Access Management</h2>
            <p class="text-muted mb-5">
              <b>Drop-in replacement for your  DIY Authentication
              and Identity Management </b>
<ul>
 <li> OpenID compliance with customizable JWT token generation  </li> 

 <li> Support external identity providers via SAML, OpenID and Social Login </li> 

 

 <li> Plug and play with leading Identity Provider like Azure Active Directory and AWS Cognito </li> 
</ul>

            </p>
 
            <a href="javascript: void(0);" class="btn btn-primary"
              v-scroll-to="'#demo'"
              >Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon
            ></a>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img
                  src="@/assets/images/IDM_Dashboard.gif"
                  alt=""
                  class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                src="@/assets/images/dot-img.png"
                alt=""
                class="dot-img-left"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <div class="row align-items-center section pb-5 mb-5">
          <div class="col-md-6">
            <div class="position-relative mb-md-0 mb-5">
              <div class="me-5 features-img">
                <img
                  src="@/assets/images/SaasLayer-Analytics.png"
                  alt=""
                  class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                src="@/assets/images/dot-img.png"
                alt=""
                class="dot-img-right"
              />
            </div>
          </div>
          <div class="col-md-5 ms-md-auto">
            <h2 class="mb-4">Reporting and Analytics</h2>
            <p class="text-muted mb-5">
           <b>   Full-stack serverless analytics and reporting platform. </b>
              <ul> 
             <li>   Deliver dashboard and custom metrics in no time </li>

<li>Stream events to our data lake and data pipeline for real time analytics </li>

<li>Support major relational database, or event source from Google analytics or IoT devices </li>


              </ul>
            </p>
            <a href="javascript: void(0);" class="btn btn-primary"
              v-scroll-to="'#demo'"
              >Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon
            ></a>
          </div>
        </div>

         <div class="row align-items-center mb-5">
          <div class="col-md-5 order-2 order-md-1 mt-md-0 mt-5">
            <h2 class="mb-4">Admin Builder</h2>
            <p class="text-muted mb-5">
              <b> Admin UI generator from your 
database schema
 </b>
<ul>
 <li> Deliver stunning Admin UI for your product in record time  </li>

<li>Support for complex data model and relationship  </li>

<li>Options for complex authorization policy at the object and row level </li>

 <li>Security best practice at the highest level during development, build and deployment </li>


</ul>

            </p>
            <a href="javascript: void(0);" class="btn btn-primary"
              v-scroll-to="'#demo'"
              >Find out more
              <arrow-right-icon class="icon-xs ms-2"></arrow-right-icon
            ></a>
          </div>
          <div class="col-md-6 ms-md-auto order-1 order-md-2">
            <div class="position-relative">
              <div class="ms-5 features-img">
                <img
                  src="@/assets/images/SaasLayer-Admin-Builder.png"
                  alt=""
                  class="img-fluid d-block ms-auto rounded shadow"
                />
              </div>
              <img
                src="@/assets/images/dot-img.png"
                alt=""
                class="dot-img-left"
              />
            </div>
          </div>
        </div>

        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Features end -->

    <section class="section bg-gradient-primary"  id="demo">
      <div
        class="bg-overlay-img"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/demos.png') + ')',
        }"
      ></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="text-center">
              <h1 class="text-white mb-4">Request for Demo </h1>
              <p class="text-white mb-5 font-size-18">
                Book a techical deep dive with our consultant. 
                Learn how you can up your game by adopting modern tech stacks and industry best practices


              </p>
              <a href="mailto:hello@cloudstory.dev?subject=Request for SaaSLayer Demo" class="btn btn-lg btn-light">Request for Demo</a>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- Cta end -->
  </div>
</template>
