<script>
export default {
  data() {
    return {};
  },
};
</script>

<template>
  <!-- Footer Start -->
  <footer
    class="footer"
    :style="{
      'background-image':
        'url(' + require('@/assets/images/footer-bg.png') + ')',
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="mb-4">
            <router-link to="/"
              ><img
                src="@/assets/images/saaslayer-logo-white-xs.png"
                alt=""
                class=""
                height="42"
            /></router-link>
            <p class="text-white-50 my-4 h5">
              Serverless Identity Management, Analytics and Admin Builder
            </p>
          </div>
        </div>
        <!-- end col -->

  <div class="col-lg-4 ms-lg-auto">
          <div class="row">
            <div class="col-lg-3 col-6"></div>
            <div class="col-lg-6 col-6">
              <div class="mt-4 mt-lg-0">
                <h4 class="text-white font-size-18 mb-3">Quick Links</h4>
                <ul class="list-unstyled footer-sub-menu">
                
                  <li >
                     <a href="https://medium.com/cloudstory" target="_new"  
                           class="footer-link"
                     >Cloud Story Blog </a>
                  </li>

                  <li>
                    <a target="_demo"
                      href="https://youtu.be/8kquNx7RqyY"
                      class="footer-link"
                    >
                      IDM Dashboard Demo</a
                    >
                  </li>
                 
                      <li>
                    <a target="_demo"
                      href="https://youtu.be/tj-oa4aNHnY"
                      class="footer-link"
                    >
                      IDM Event And Actions </a
                    >
                  </li>
                      <li>
                    <a target="_demo"
                      href="https://youtu.be/2OveP-KZqjo"
                      class="footer-link"
                    >
                      Reporting Dashboard  </a
                    >
                  </li>
      


                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-6"></div>
          </div>
        </div>

        <div class="col-lg-4 ms-lg-auto">
          <div class="row">
            <div class="col-lg-3 col-6"></div>
            <div class="col-lg-6 col-6">
              <div class="mt-4 mt-lg-0">
                <h4 class="text-white font-size-18 mb-3">Contact Us</h4>
                <ul class="list-unstyled footer-sub-menu">
                  <li class="text-white-50 h5">
                    Cloud Story LLP
                  </li>

                  <li>
                    <a
                      href="mailto:hello@cloudstory.dev?subject=Enquiry"
                      class="footer-link"
                    >
                      hello@cloudstory.dev</a
                    >
                  </li>
                  

                  <li class="text-white-50">
                    100 Peck Seah Street, #08-14 (PS100) Singapore
                  </li>
                
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-6"></div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mt-5">
            <p class="text-white-50 f-15 mb-0">
              {{ new Date().getFullYear() }}
              © Cloud Story LLP. Design By Themesbrand
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </footer>
  <!-- Footer End -->
</template>
